<template>
  <section
    id="overview"
  >
    <core-jumbotron-mobile />
    <heading class="text-capitalize">
      {{ $t("menu.about_us") }}
    </heading>

    <v-container>
      <v-row
        align="center"
        class="mb-5"
      >
        <v-col
          cols="12"
          md="4"
        >
          <v-card outlined>
            <v-img

              src="/static/project6.jpg"
              height="250"
            />
          </v-card>
        </v-col>

        <v-col
          class="px-3"
          cols="12"
          md="8"
        >
          <p class="title">
            {{ $t("about_us.introducting_farmers_technology") }}
          </p>

          <p class="body-1">
            {{ $t("about_us.farmers_around_the_world") }}
          </p>
        </v-col>
      </v-row>

      <v-row
        align="center"
        class="mb-5"
      >
        <v-col
          class="px-3"
          cols="12"
        >
          <p class="title">
            {{ $t("about_us.oria_distribution_network") }}
          </p>
          <p class="body-1">
            {{ $t("about_us.distribution_partners_products") }}:
          </p>
          <ul class="body-1 mb-5">
            <li>{{ $t("about_us.dealers") }}</li>
            <li>{{ $t("about_us.distributors") }}</li>
            <li>{{ $t("about_us.oems") }}</li>
            <li>{{ $t("about_us.cooperatives_and_associations") }}</li>
          </ul>
        </v-col>
      </v-row>
      <v-row
        align="center"
        justify="center"
        class="fill-height"
      >
        <v-col
          cols="12"
          md="4"
        >
          <v-card
            shaped
            outlined
          >
            <v-img
              src="/static/map.jpg"
              height="250"
            />
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="8"

          class="text-left"
        >
          <p class="title">
            {{ $t("about_us.central_access_europe") }}
          </p>

          <p class="body-1">
            {{ $t("about_us.oria_head_office") }}
          </p>
          <ul class="body-1">
            <li>{{ $t("about_us.product_training") }}</li>
            <li>{{ $t("about_us.parts_sales") }}</li>
            <li>{{ $t("about_us.technical_support") }}</li>
            <li>{{ $t("about_us.installations") }}</li>
            <li>{{ $t("about_us.repairs") }}</li>
            <li>{{ $t("about_us.warranty") }}</li>
          </ul>
        </v-col>
      </v-row>

      <v-row
        align="center"
        class="mb-5"
      >
        <v-col
          cols="12"
          md="12"
        >
          <p class="title">
            {{ $t("about_us.team_oria_agriculture") }}
          </p>
          <p class="body-1">
            {{ $t("about_us.european_team") }}
          </p>

          <p class="body-1">
            {{ $t("about_us.oria_founders") }}
          </p>
        </v-col>
      </v-row>

      <v-row
        align="center"
        class="mb-5"
      >
        <v-col
          cols="12"
          md="4"
        >
          <v-card outlined>
            <v-img
              gradient="to top right, rgba(0,117,131,0.3), rgba(0,117,131,.7)"
              src="/static/danea.jpg"
              height="175"
            />
          </v-card>
        </v-col>

        <v-col
          class="px-3"
          cols="12"
          md="8"
        >
          <p class="title">
            Danea Armstrong
          </p>

          <p class="body-1">
            {{ $t("about_us.danea") }}
          </p>
        </v-col>
      </v-row>

      <v-row
        align="center"
        class="mb-5"
      >
        <v-col
          cols="12"
          md="4"
        >
          <v-card outlined>
            <v-img
              gradient="to top right, rgba(0,117,131,0.3), rgba(0,117,131,.7)"
              shaped
              src="/static/bertrand.jpg"
              height="175"
            />
          </v-card>
        </v-col>

        <v-col
          class="px-3"
          cols="12"
          md="8"
        >
          <p class="title">
            Bertrand Plessis
          </p>

          <p class="body-1">
            {{ $t("about_us.bertrand") }}
          </p>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
  import i18n from '../i18n'

  export default {
    metaInfo: {
      title: i18n.t('menu.about_us'),
      meta: [
        { name: 'description', content: 'Oria Agriculture' },
      ],
    },

    components: {
      Heading: () => import('@/components/Heading'),

      CoreJumbotronMobile: () => import('@/components/core/JumbotronMobile'),
    },
  }
</script>
